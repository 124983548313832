.nav-pills {
	.nav-link {
		@extend .mb-1;
		border-radius: .6rem 0 0 .6rem;
		background-color: #62749F;
		color: #fff;
		font-family: Open sans, sans-serif;

		&.active {
			background-color: #fff;
			color: #62749F;
		}
	}
}