#side-nav-category {
	.card-header {
		h5 {
			a.collapsed > .fa-minus {
				display: none;
			}
			a.collapsed > .fa-plus {
				display: inline;
			}
			a > .fa-plus {
				display: none;
			}
			a > .fa-minus {
				display: inline;
			}
		}
	}
}