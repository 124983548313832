/* ======= BOOTSTRAP VARIABLES, IMPORT & FONTS ======= */

$body-color: #A7A9AC;
$primary: #910048;
$secondary: #6BA4B8;
$primary-light: rgba(183, 106, 132, 0.6);
$secondary-light: rgba(107, 164, 184, 1);
$secondary-ultra-light: rgba(231, 240, 244, 1);
$blue-primary: #002D72;
$blue-primary-light: rgba(0, 45, 114, .45);
$blue-dark: #3D5466;
$grey-light: rgba(158, 168, 181, .4);
$grey-dark: rgba(136, 153, 169, .6);
$grey-ultra-light: #F4F5F7;
$headings-color: #002D72;
$theme-colors: (
	"primary-light": $primary-light,
	"secondary-light": $secondary-light,
	"secondary-ultra-light": $secondary-ultra-light,
	"blue-primary": $blue-primary,
	"blue-primary-light": $blue-primary-light,
	"blue-dark": $blue-dark,
	"grey-dark": $grey-dark,
	"grey-light": $grey-light,
	"grey-ultra-light": $grey-ultra-light
);
$sizes: ();
$sizes: map-merge((
	5: 5%,
	10: 10%,
	15: 15%,
	25: 25%,
	30: 30%,
	40: 40%,
	50: 50%,
	60: 60%,
	65: 65%,
	70: 70%,
	75: 75%,
	100: 100%,
	auto: auto
), $sizes);
$font-family-sans-serif: Arial, Roboto, "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$headings-font-family: "Open Sans", sans-serif;
$navbar-nav-link-padding-x: 1rem;
$navbar-dark-color: rgba(255, 255, 255, .7);
$navbar-dark-hover-color: rgba(255, 255, 255, .85);
$small-font-size: 85%;
$btn-font-weight: 700;
$btn-border-width: 2px;
$btn-padding-x: 1.5rem;
$btn-padding-x-sm: 1rem;
$btn-padding-x-lg: 2rem;
$border-radius: .6rem;
$border-radius-lg: .7rem;
$border-radius-sm: .5rem;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $body-color;
$breadcrumb-active-color: $blue-primary;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: .75rem;
$breadcrumb-divider: quote("•");
$pagination-color: $secondary;
$pagination-bg: $secondary-ultra-light;
$pagination-border-color: #D5D5D5;
$pagination-hover-bg: $secondary;
$pagination-hover-color: #FFF;
$pagination-hover-border-color: $secondary;
$pagination-active-bg: $secondary;
$pagination-disabled-bg: $secondary-ultra-light;
$pagination-disabled-border-color: #D5D5D5;
$pagination-focus-box-shadow: 0 0 0 0.2rem rgba(107, 164, 184, .25);
$close-color: #FFF;
$close-text-shadow: 0 1px 0 #000;
$hr-border-width: 2px;
$input-plaintext-color: #000;
$input-group-addon-bg: #FFF;
$input-btn-padding-y: .75rem !default;
$custom-control-indicator-checked-color: #000;
$custom-control-indicator-checked-bg: #FFF;
$custom-control-indicator-size: 2rem;
$custom-control-indicator-bg: #FFF;
$custom-control-gutter: 2.5rem;
$custom-checkbox-indicator-border-radius: 0;
$custom-control-indicator-active-bg: #FFF;
$custom-control-indicator-focus-box-shadow: 0 0 0 .2rem rgba($secondary, .25);
$input-color: #000;
$input-focus-bg: rgba(166,199,213,.6);
$input-focus-border-color: $secondary;
$input-focus-color: $blue-dark;
$input-focus-box-shadow: 0 0 0 0 rgba($secondary, .25);
// $input-focus-box-shadow: 0 0 0 .2rem rgba($secondary, .25);


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

@import '../../node_modules/bootstrap/scss/functions';
@import 'bootstrap-variables';
@import 'font-awesome-variables';

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/font-awesome/scss/font-awesome';

@import 'theme/tabs';
@import 'theme/sidebar';
@import 'theme/products';
@import 'theme/navbar';
@import 'theme/selectpicker';
@import 'theme/home';



/* ======= LAYOUT ======= */

.bg {	
	background-color: #F4F5F7;
}

.category-image {
	.position-absolute {
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		overflow: hidden;
	}
}

.p-6 {
	padding: 5rem !important;
}



/* ======= HEADER ======= */

header {
	#header-bottom {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
		#main-nav {
			display: flex;
			flex-basis: auto;
			flex-grow: 0;
			align-items: center;
			@include media-breakpoint-up(md) {
				flex-grow: 1;
			}
			.nav-link {
				padding-right: .5rem;
				padding-left: .5rem;
				@include media-breakpoint-up(md) {
					padding-right: 1rem;
					padding-left: 1rem;
				}
			}
			form, #header-logo-sec {
				display: none;
				@include media-breakpoint-up(lg) {
					display: flex;
				}
			}
			form {
				input[type="search"] {
					border-top-left-radius: 5rem;
					border-bottom-left-radius: 5rem;
				}
				.input-group-append button {
					border-top-right-radius: 5rem;
					border-bottom-right-radius: 5rem;
				}
			}
			#header-user i {
				font-size: 1.4em;
				@include media-breakpoint-up(md) {
					font-size: 2em;
				}
			}
			#header-cart {
				span {
					width: 1.4em;
					height: 1.4em;
					line-height: 1.4em;
					@include media-breakpoint-up(md) {
						width: 2em;
						height: 2em;
						line-height: 2em;
					}
					.fa-stack-2x {
						font-size: 1.4em;
						@include media-breakpoint-up(md) {
							font-size: 2em;
						}
					}
					strong {
						font-size: .5rem;
						margin-top: -0.2em;
						margin-left: .2em;
						font-weight: 400;
						color: #FFF;
						@include media-breakpoint-up(md) {
							font-size: .875rem;
							margin-top: -0.25em;
						}
					}
				}
			}
		}
		ul.navbar-nav li a {
			@extend .text-blue-primary;
		}
		&.is-sticky {
			transition: .15s linear;
			background-color: $blue-primary !important;
			form, #header-logo-sec {
				display: none;
			}
			ul.navbar-nav li a {
				@extend .text-white;
				@include hover-focus {
					color: rgba(255, 255, 255, 0.85) !important;
				}
			}
			#header-cart span strong {
				@extend .text-blue-primary;
				font-weight: 700;
			}
		}
	}
	.sticky.is-sticky {
	    position: fixed;
	    left: 0;
	    right: 0;
	    top: 0;
	    z-index: 1000;
	    width: 100%;
	}
}

/* ======= OFF CANVAS ======= */

.offcanvas-collapse {
	@include media-breakpoint-down(md) {
		position: fixed;
		top: 70px; /* Height of navbar */
		bottom: 0;
		left: 100%;
		width: 100%;
		padding-right: 1rem;
		padding-left: 1rem;
		overflow-y: auto;
		visibility: hidden;
		background-color: $blue-primary;
		transition-timing-function: ease-in-out;
		transition-duration: .3s;
		transition-property: left, visibility;
		z-index: 999;
		&.open {
			left: 0;
			visibility: visible;
		}
	}
}


/* ======= FOOTER ======= */

footer {
	a {
		color: inherit;
	}
	hr {
		@include media-breakpoint-up(xl) {
			max-width: 85%;
			margin-left: 0;
		}
	}
	#footer-top {
		background-color: $grey-light;
		color: $blue-dark;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
		h5 {
			margin-bottom: 1.2rem;
		}
		ul.list-unstyled > li {
			margin-bottom: .5rem;
			font-size: .875rem;
		}
		#social-footer {
			@include media-breakpoint-up(xl) {
				max-width: 80%;
			}
		}
	}
	#footer-bottom {
		background-color: $grey-dark;
		color: $blue-dark;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
}



/* ======= TYPO ======= */

h1,
.h1 {
	font-size: 2.375rem;
	font-weight: 700;
	@include media-breakpoint-down(md) {
		font-size: 1.75rem;
	}
}

h2,
.h2 {
	font-size: 1.875rem;
	font-weight: 400;
	@include media-breakpoint-down(md) {
		font-size: 1.25rem;
	}
}

h3,
.h3 {
	font-size: 1.625rem;
	font-weight: 400;
	@include media-breakpoint-down(md) {
		font-size: 1rem;
	}
}

h4,
.h4 {
	font-size: 1.125rem;
	font-weight: 700;
	@include media-breakpoint-down(md) {
		font-size: 1.125rem;
	}
}

h5,
.h5 {
	font-size: 1.125rem;
	font-weight: 400;
	color: $blue-dark;
	@include media-breakpoint-down(md) {
		font-size: .875rem;
	}
}

h6,
.h6 {
	font-size: 1.125rem;
	font-weight: 700;
	color: $blue-dark;
	@include media-breakpoint-down(md) {
		font-size: .875rem;
	}
}

.blockquote {
	font-size: 1.5rem;
	font-style: italic;
}

hr {
	&.hr-white {
		border-top-color: #FFF;
	}
	&.hr-grey-dark {		
		border-top-color: $grey-dark;
	}
	&.hr-blue-dark {
		border-top-color: $blue-dark;
	}
}

/* ======= BUTTONS ======= */

.btn {
	text-transform: uppercase;
	font-size: 1rem;
	font-family: 'Open Sans', sans-serif;
	&.btn-sm {
		font-size: .875rem;
	}
	&.btn-lg {
		font-size: 1.125rem;
	}
	&.btn-primary:focus, &:focus {
		box-shadow: none;
	}
}



/* ======= SIDE NAVS ======= */

#side-nav-account {
	width: 100%;
	@include media-breakpoint-up(lg) {
		padding-right: 3rem;
	}
	.nav-box {
		margin-bottom: 1.25rem;
		@include media-breakpoint-down(xs) {
			margin: 0 auto;
			padding: 0;
			border: 0 none;
			box-shadow: none;
			display: inline-block;
			width: calc( ( 100% - 2rem ) / 6);
			vertical-align: baseline;
		}
		i.fa {
			@include media-breakpoint-down(xs) {
				font-size: 1.75rem;
			}
		}
	}
}

#side-nav-category {
	width: 100%;
	.card {
		border: 0 none;		
		.card-header {
			background-color: #FFF;
			border-bottom: 0 none;
			&  + div .card-header {
				padding-left: 0;
				&  + div .card-header {
					padding-left: 0;
				}
			}
			h5 {
				border-bottom: 1px solid #DEE2E6;
				padding-bottom: 1rem;
				font-size: .875rem;
				a {
					color: $blue-dark;
					transition: .15s linear;
					&:hover, &[aria-expanded="true"] {
						font-weight: 700;
						color: $secondary;
						text-decoration: none;
					}
				}
			}
			&.active a {
				font-weight: 700;
				color: $secondary;
				text-decoration: none;
			}
		}
		.card-body .card-body {
			padding-left: 0;
		}
	}
	> .card {		
		&:first-of-type {
			padding-top: 1rem;
		}
		&:last-of-type {
			padding-bottom: 1rem;
		}
	}
}



/* ======= BREADCRUMBS ======= */

.breadcrumb {
	margin: 2.75rem 0;
	text-transform: uppercase;
	.breadcrumb-item {
		font-size: 0.75rem;
		&.active {
			font-weight: 600;
		}
		> a {
			color: $body-color;
		}
	}
}

.shipping-breadcrumbs {
	> .shipping-breadcrumbs-step {
		padding: 0;
		position: relative;
		p.h2 {
			font-size: 1rem;
			@include media-breakpoint-up(md) {
				font-size: 1.875rem;
			}
		}
		> .shipping-breadcrumbs-dot {
			position: absolute;
			width: 3.5rem;
			height: 3.5rem;
			display: block;
			background: $progress-bg;
			top: 15%;
			left: 50%;
			transform: translate(-50%, 0);
			margin-top: -1.25rem;
			border-radius: 50%;
			@include media-breakpoint-up(md) {
				width: 5.5rem;
				height: 5.5rem;
				top: 0;
			}
			&:after {
				content: ' ';
				width: 2.75rem;
				height: 2.75rem;
				background: #FFF;
				border-radius: 50%;
				position: absolute;
				top: .4rem;
				left: .35rem;
				@include media-breakpoint-up(md) {
					width: 4.5rem;
					height: 4.5rem;
					top: .5rem;
					left: .5rem;
				}
			}
			span {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 99;
				font-size: 1.25rem;
				font-weight: 800;
				color: $progress-bg;
				@include media-breakpoint-up(md) {
					font-size: 2rem;
				}
			}
		}
		> .progress {
			position: relative;
			border-radius: 0;
			border: 0 none;
			height: 10px;
			box-shadow: none;
			margin: 1.25rem 0;
			> .progress-bar {
				width: 0px;
				box-shadow: none;
				background: $primary;
			}
		}
		&.complete, &.active {
			> .progress > .progress-bar {
				width: 100%;
			}
			> .shipping-breadcrumbs-dot {
				background: $primary;
				span {
					color: $primary;
				}
			}
		}
	}
}



/* ======= TABLE ======= */

.table {
	color: #777;
}



/* ======= FORM ======= */

.custom-checkbox {
	.custom-control-label:before {
		border: 1px solid #A7A9AC;
	}
}

.custom-control-input:focus~.custom-control-label:before {
	border: 1px solid $secondary;
}

.form-control {
	padding: .75rem;
}

/**
 * Remove input number spinner
 */
/* For Firefox */
input[type='number'] {
    -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* ======= ALERT ======= */

.alert {
	#stock-discount & {
		background-color: #98A1BE;
		border-color: #98A1BE;
		color: #FFF;
		margin-top: 1rem;
	}
}



/* ======= MODALS ======= */

.modal {
	.modal-content {
		border: 10px solid #FFF;
		.modal-header {
			border-bottom-color: transparent;
			padding: 1rem 1rem 0 3rem;
		}
		.modal-body {
			color: #FFF;
			padding: 0 3rem 1rem 3rem;
			h4 {
				font-size: 1.5rem;
				margin-top: 1rem;
			}		
		}
	}
}



/* ======= TOOLTIPS ======= */

.tooltip {
	.tooltip-inner {
		font-size: .875rem;
		padding: .75rem;
		background-color: $blue-dark;
	}
}
.arrow {
	.bs-tooltip-top & {
		&:before {
			border-top-color: $blue-dark;
		}
	}
	.bs-tooltip-right & {
		&:before {
			border-right-color: $blue-dark;
		}
	}
	.bs-tooltip-bottom & {
		&:before {
			border-bottom-color: $blue-dark;
		}
	}
	.bs-tooltip-left & {
		&:before {
			border-left-color: $blue-dark;
		}
	}
}



/* ======= PROGRESS BAR ======= */

.progress {
	border: 2px solid #FFF;
	.progress-bar {
		background-color: #62749F;
		font-size: 1.2rem;
		font-weight: 700;
	}
}



/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 3rem;
	height: 3rem;
	border: 1px solid #FFF;
	border-radius: 50%;
	background: $blue-dark;
	bottom: 10%;
	right: 5%;
	cursor: pointer;
	z-index: 99;
	transition: .15s linear;
	&:hover {
		opacity: 0.7;
	}
	#top-arrow {
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 1rem;
		height: 1rem;
		margin: 1.1rem auto;
	}
}



/* ======= LOGIN ======= */

main {
	height: auto;
	@include media-breakpoint-up(lg) {
		height: 100%;
	}
	#login {
		@include media-breakpoint-up(lg) {
			min-height: 100vh !important;
		}
		#login-left {
			width: 100%;
			@include media-breakpoint-up(lg) {
				flex: 0.45;
			}
			ul {
				li {
					margin-bottom: 1.5rem;
					@include media-breakpoint-up(xl) {
						font-size: 1.125rem;
					}
				}
			}
			> div > div {
				@include media-breakpoint-up(xl) {
					width: 75%;
					margin-left: auto;
					margin-right: auto;
				}
			}
			#login-contact {
				background-color: $grey-light;
				color: $blue-dark;
				a {
					color: inherit;
				}
			}
			#login-social {
				@include media-breakpoint-up(xl) {
					width: 50%;
				}
			}
			#login-footer {
				background-color: $grey-dark;
				color: $blue-dark;
			}
		}
		#login-right {
			width: 100%;
			padding: 2em;
			@include media-breakpoint-up(lg) {
				flex: 0.55;
				> div {
					width: 60%;
					margin: 0 auto;
				}
			}		
		}
		#login-logo {
			width: 100%;
			background-color: $grey-light;
			text-align: center;
		}
	}
}


/* ======= CHARTJS ======= */

.chart-container {
	height: 15rem;
}



/* ======= OTHER ======= */

.no-ws {
	white-space: normal;
}

.nav-box {
	border: 2px solid #DBDBDB;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, .15);
	padding: 1rem;
	white-space: normal;
	@include media-breakpoint-down(sm) {
		margin-bottom: 2rem;
	}
	i.fa {
		color: $blue-dark;
		margin-top: .5rem;
		margin-bottom: 1rem;
		transition: .15s linear;
	}
	h3 {
		color: $blue-dark;
		font-size: 1.5rem;
		transition: .15s linear;
		font-weight: 600;
		@include media-breakpoint-down(md) {
			font-size: 1.125rem;
		}
	}
	&:hover,
	&.active {		
		i.fa {
			color: $secondary;
		}
		h3 {
			color: $secondary;
		}
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(0,0,0,.15);
	}
}

.product-box {
	width: 100%;
	height: auto;
	padding: 0;
	margin: 0 auto 2em auto;
	overflow: hidden;
	position: relative;
	.product-overlay {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(61, 84, 102, .65);
		transition: .15s linear;
	}
	.product-img {
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;
		img {
			width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			bottom: auto;
			right: auto;
			transform: translateX(-50%) translateY(-50%);
		}
	}
	.product-content {
		z-index: 2;
		text-align: center;
		color: rgba(0,0,0,.5) !important;
		width: 90%;
		position: absolute;
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		margin-top: 0;
		transform: translateX(-50%) translateY(-50%);
		.product-name {			
			transition: .15s linear;
			a {
				font-size: 1.125rem;
				font-weight: 700;
				color: #FFF;
				margin-bottom: 0;
				&:hover {
					text-decoration: none;
					color: darken(#FFF, 15%);
				}
			}
		}
		.product-more {
			position: absolute;
			left: 50%;
			top: 50%;
			bottom: auto;
			right: auto;
			transform: translateX(-50%) translateY(-50%);
			top: 90%;
			transition: .15s linear;
			opacity: 0;
		}
	}
	// &.hover {
	&:hover {
		@include media-breakpoint-up(lg) {
			.product-overlay {
				background: rgba(0, 45, 114, 0.65);
			}
			.product-name {
				opacity: 0;
			}
			.product-more {
				opacity: 1;
				top: 50%;       	
		    }
		}
	}
}

.box-evidence {
	color: $blue-dark;
	padding: 2rem 1rem;
	h4 {
		color: $blue-dark;
		margin-bottom: 1rem;
		font-size: 1.25rem;
	}
}

.cart-more-info {
	.card {
		border-right: 0;
		border-left: 0;
		button {
			text-transform: none;
			font-size: 1.125rem;
			&.focus,
			&:focus {
				box-shadow: 0 0 0 0rem rgba(0,0,0,.25);
				background-color: #f4f5f7 !important;
			}
		}
	}
}

.btn {
	span {
		white-space: normal;
	}
}