header #header-bottom {
	svg {
		min-width: 200px;
	}

	&.is-sticky {
	    @extend .navbar-dark;

	    svg {
			min-width: 0;
	    	@extend .w-100;
	    	.cls-3 {
	    		fill: #fff;
	    	}
	    }
	}
}

